// .checkmarks ul, ul.checkmarks {
//   list-style: none;

//   & > li:before {
//     content: '✓';
//   }
// }
.checkmarks {
  ::marker {
    content: '✓ ';
  }
}
// .rdw-editor-wrapper {
// }

div.ant-input-number {
  width: 100%;
}

.ant-alert {
  margin-bottom: 10px;
}

.ant-tabs {
  overflow: visible; // => display shadow below edge buttons on press
  margin-bottom: 20px;
}

.hide-form-item-colon label:after {
  content: ' ';
  margin: 0 4px 0 2px;
}

.ant-alert-message {
  ul {
    margin-bottom: 0;
  }
}

.ant-modal.modal-top-50px {
  top: 50px !important;
}

.table-first-colum-min-width {
  thead tr:first-child th:first-child {
    width: 1px;
  }
}

.ant-form-item-label > label {
  white-space: break-spaces;
}
.ant-row.row-reverse {
  flex-direction: row-reverse;
  label {
    float: left;
  }
}

.ant-form-item-label {
  > label {
    white-space: break-spaces;
  }
}

.ant-modal-mask {
  //fix toolbar above modal
  z-index: 10000 !important;
}
.ant-modal-wrap {
  //fix toolbar above modal
  z-index: 10000 !important;
}

.ant-dropdown,
.ant-menu-submenu-popup,
.ant-select-dropdown,
.ant-tooltip {
  z-index: 20000 !important;
}

// .ant-layout-sider-children {
//   .ant-menu-item-only-child {
//     padding: 0 calc(50% - 20px / 2) !important;
//   }
// }

.grey-link {
  color: rgba(0, 0, 0, 0.85);
}

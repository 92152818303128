@import 'styles/variables.less';

.display-none {
  display: none;
}
.multiline {
  white-space: pre-line;
}
.margin-bottom-0 {
  margin-bottom: 0;
}

.white-background {
  background: white;
}

.padding-16 {
  padding: 16px;
}

.margin-16 {
  margin: 16px;
}

.margin-right-16 {
  margin-right: 16px;
}
.margin-top-0 {
  margin-top: 0;
}

.pointer-not-last-cell .ant-table-cell:not(:last-child) {
  cursor: pointer;
}

.input-confirm-modal .ant-form-item > .ant-col-18 {
  max-width: 100% !important;
  flex: 1 1 !important;
}
.flex-1 {
  flex: 1;
}
.flex-3 {
  flex: 3;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.sub-title {
  color: rgb(79, 79, 79);
  font-style: italic;
}
.white-space-nowrap {
  white-space: nowrap;
}

.flex-1 {
  flex: 1;
}
.flex-3 {
  flex: 3;
}

.hover-background-light-primary:hover {
  background-color: @primary-light-color;
}

.border-gray {
  border: 1px solid #d9d9d9;
}
.otp-input {
  display: flex;
  > :first-child {
    max-width: 150px;
    min-width: 100px;
    margin-right: 16px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.remove-right-border {
  border-right: none !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

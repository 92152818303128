@import '~antd/lib/style/index';

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

// .make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);
.make-container(@screen-xxl-min, @screen-xxl); // Optional
